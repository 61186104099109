#home {
  position: relative;
  background: url("../../assets/bgIMG.png");
  background-size: cover;
  background-position: center;
  background-repeat: repeat;

  // disable the default padding styles
  .app__wrapper {
    padding: 0;
    .copyright {
      display: none;
    }
  }
}

.app__header {
  padding-top: 2rem;
  margin-top: 7rem
}

.container {
  justify-content: center;
  position: relative;
  max-width: 77.5rem;
}

.gradient-border {
  position: relative; 
  text-align: center; 
  max-width: 62rem;
  margin-bottom: 3.875rem;
  z-index: 1;

  @media (min-width: 768px) { 
    margin-bottom: 5rem; 
  }
  @media (min-width: 1024px) { 
    margin-bottom: 6.25rem;
 }
}

.creative-toolbox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem; /* Corresponds to gap-6 */
  margin-bottom: 1.75rem; /* Corresponds to mb-7 */
}

.hero-text {
  display: inline-block;
  font-size: 3rem; /* Corresponds to text-3xl */
  font-weight: 900; /* Corresponds to font-black */

  span {
    color: var(--secondary-color);
  }

  @media (max-width: 450px) {
    font-size: 2rem; /* Corresponds to lg:text-5xl */
  }

  /* For larger screens (lg) */
  @media (min-width: 1024px) {
    font-size: 3.25rem; /* Corresponds to lg:text-5xl */
  }

  /* For extra-large screens (xl) */
  @media (min-width: 1280px) {
    font-size: 4.5rem; /* Corresponds to xl:text-7xl */
  }
}

.hero-desc {
  font-size: 1.5rem; /* Corresponds to text-lg */
  max-width: 500px; /* Corresponds to max-w-[500px] */
  text-align: center;
  @media (max-width: 798px) {
    font-size: 1rem; /* Corresponds to lg:text-5xl */
  }
  @media (max-width: 1024px) {
    font-size: 1.125rem; /* Corresponds to lg:text-5xl */
  }
}

  .hero-card {
    margin-top: 1rem;
    background: #07182E;
    position: relative;
    display: flex;
    place-content: center;
    place-items: center;
    overflow: hidden;
    border-radius: 20px;
  }
  
  .robot-image {
    width: 98%; 
    border-radius: 20px;
    margin-bottom: 5rem;
    transform: translateY(20%);
    @media (min-width: 768px) {
      
      }
    @media (min-width: 1024px) { 
      transform: translateY(10%);
      }
  }
  
  .hero-card::before {
    content: '';
    position: absolute;
    height: 50px;
    width: 100px;
    background-image: linear-gradient(180deg, rgb(0, 183, 255), rgb(255, 48, 255));
    height: 200%;
    animation: rotBGimg 3s linear infinite;
    transition: all 0.2s linear;
  }
  
  @keyframes rotBGimg {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  .hero-card::after {
    content: '';
    position: absolute;
    background: #07182E;
    z-index: -1;;
    inset: 5px;
    border-radius: 15px;
  } 

.learn-more {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  font-family: inherit;
  margin: 1rem;
 }

 a {
  text-decoration: none;
  font-weight: 600;
  font-size: 15px;
  color: #382b22;
 }
 
 .learn-more {
  padding: 0.7em 1rem;
  background: var(--primary-color);
  border: 2px solid var(--secondary-color);
  border-radius: 0.75em;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: background 150ms cubic-bezier(0, 0, 0.58, 1), -webkit-transform 150ms cubic-bezier(0, 0, 0.58, 1);
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1), -webkit-transform 150ms cubic-bezier(0, 0, 0.58, 1);
 }
 
 .learn-more.learn-more::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background:var(--secondary-color);
  border-radius: inherit;
  -webkit-box-shadow: 0 0 0 2px var(--secondary-color) 0 0.625em 0 0 var(--primary-color);
  box-shadow: 0 0 0 2px var(--secondary-color) 0 0.625em 0 0 var(--primary-color);
  -webkit-transform: translate3d(0, 0.75em, -1em);
  transform: translate3d(0, 0.75em, -1em);
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1), -webkit-transform 150ms cubic-bezier(0, 0, 0.58, 1), -webkit-box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
 }
 
 .learn-more.learn-more:hover {
  background: var(--primary-color);
  -webkit-transform: translate(0, 0.25em);
  transform: translate(0, 0.25em);
 }
 
 .learn-more.learn-more:hover::before {
  -webkit-box-shadow: 0 0 0 2px var(--secondary-color), 0 0.5em 0 0 var(--primary-color);
  box-shadow: 0 0 0 2px var(--secondary-color), 0 0.5em 0 0 var(--primary-color);
  -webkit-transform: translate3d(0, 0.5em, -1em);
  transform: translate3d(0, 0.5em, -1em);
 }
 
 .learn-more.learn-more:active {
  background: var(--primary-color);
  -webkit-transform: translate(0em, 0.75em);
  transform: translate(0em, 0.75em);
 }
 
 .learn-more.learn-more:active::before {
  -webkit-box-shadow: 0 0 0 2px var(--secondary-color), 0 0 var(--primary-color);
  box-shadow: 0 0 0 2px var(--secondary-color), 0 0 var(--primary-color);
  -webkit-transform: translate3d(0, 0, -1em);
  transform: translate3d(0, 0, -1em);
 }