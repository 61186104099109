.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.profile_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  @media screen and (max-width: 450px) {
    margin-top: 0.5rem;
  }
  @media screen and (max-width:1406px) {
    flex-direction:column;
  }
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.app__profile-item {
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3rem;

  img {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    object-fit: contain;
  }

  @media screen and (min-width: 2000px) {
    width: 50%;
    margin: 2rem 4rem;

    img {
      height: 320px;
      width: 320px;
    }
  }

  @media screen and (max-width: 900px) {
    width: 90%;
    margin: 0 4rem;
  }
}

.interests {
  flex:1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 2rem;
  margin:auto;
  @media screen and (max-width: 1200px) {
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
}

.card-text {
  font-size: 1.8rem;
  font-weight: 800;
  color: var(--black-color);
  text-align: center;

  span {
    color: var(--secondary-color);
  }

  @media screen and (min-width: 2000px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 1.7rem;
  }
}

.skills {
  flex:1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin:auto;
  max-width: 1000px;
  @media screen and (max-width: 1200px) {
    justify-content: center;
    align-items: center;
  }
}

.e-card {
  background: transparent;
  box-shadow: 0px 8px 28px -9px rgba(0,0,0,0.45);
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 16px;
  overflow: hidden;

  &:hover {
    box-shadow: 0 0 20px #4a6ad3;
  }

.wave {
  position: absolute;
  width: 540px;
  height: 700px;
  opacity: 0.6;
  left: 0;
  top: 0;
  margin-left: -50%;
  margin-top: -70%;
  background: linear-gradient(744deg,#4a6ad3,#2c4cb5 60%,#182963);
}

.icon {
  height: 2em;
  width: 2em;
  margin-top: 1em;
}

.infotop {
  text-align: center;
  font-size: 20px;
  position: absolute;
  top: 2em;
  left: 0;
  right: 0;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.name {
  font-size: 14px;
  font-weight: 100;
  position: relative;
  top: 1em;
  text-transform: lowercase;
}

.wave:nth-child(2),
.wave:nth-child(3) {
  top: 210px;
}

.playing .wave {
  border-radius: 40%;
  animation: wave 3000ms infinite linear;
}

.card-title {
  font-size: 1rem;
}

.wave {
  border-radius: 40%;
  animation: wave 55s infinite linear;
}

.playing .wave:nth-child(2) {
  animation-duration: 4000ms;
}

.wave:nth-child(2) {
  animation-duration: 50s;
}

.playing .wave:nth-child(3) {
  animation-duration: 5000ms;
}

.wave:nth-child(3) {
  animation-duration: 45s;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
}