.education__training {
  width: 100%;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1281px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: 1384px) {
    width:1200px;
  }
}

  .app__education-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  
    @media screen and (max-width: 1024px) {
      margin-top: 2rem;
      justify-content: center;
      align-items: center;
    }
  }

  .app__flex-education {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .app__education-item {
    flex-direction: column;
    text-align: center;
    transition: all 0.3s ease-in-out;

    @media screen and(min-width:1281px) {
      position: sticky;
      top: 1rem; /* Adjust this value to position the card */
      z-index: 1; /* Ensure it appears on top */
    }
  
    div {
      width: 300px;
      height: 300px;
      border-radius: 50%;
      background-color: #fef4f5;
      box-shadow: 0 0 25px var(--primary-color);
  
      img {
        width: 76%;
        height: 47%;
      }
  
      &:hover {
        box-shadow: 0 0 20px var(--white-color);
      }
  
      @media screen and (min-width: 2000px) {
        width: 150px;
        height: 150px;
      }
      
  
      @media screen and (max-width: 450px) {
        width: 120px;
        height: 120px;
      }
    }
  
    p {
      font-weight: 500;
      margin-top: 0.5rem;
    }
  
    @media screen and (min-width: 2000px) {
      margin: 1rem 2rem;
  
      p {
        margin-top: 1rem;
      }
    }
  }

  .major-text {
    font-size: 1rem;
    font-weight: 1200;
    color: var(--secondary-color);
    text-align: left;
  
    @media screen and (min-width: 2000px) {
      font-size: 2rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 0.9rem;
    }
  }

  .institution-text {
    font-size: 1.5rem;
    font-weight: 1200;
    color: var(--black-color);
    text-align: center;
  
    @media screen and (min-width: 2000px) {
      font-size: 2rem;
    }
  
    @media screen and (max-width: 450px) {
      font-size: 1rem;
    }
  }
  
  .education-tooltip {
    max-width: 300px !important;
    background-color: var(--white-color) !important;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
    padding: 1rem !important;
    color: var(--gray-color) !important;
    text-align: center !important;
    line-height: 1.5 !important;
    opacity: 1 !important;
  
    @media screen and (min-width: 2000px) {
      font-size: 1.75rem !important;
      max-width: 500px !important;
      line-height: 2 !important;
    }
  }
  
  .app__trainings {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    
      @media screen and (max-width: 1024px) {
        margin-top: 2rem;
        justify-content: center;
        align-items: center;
      }
    }
    
    .app__trainings-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 1rem 0;
    }
    
    .app__trainings-works {
      flex: 1;
    
      .app__trainings-work {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1rem;
        cursor: pointer;
    
        h4 {
          font-weight: 500;
        }
    
        p {
          font-weight: 400;
          color: var(--gray-color);
          margin-top: 5px;
        }
      }
    }
    
    .app__trainings-year {
      margin-right: 3rem;
    
      p {
        font-weight: 800;
        color: var(--secondary-color);
      }
    
      @media screen and (max-width: 450px) {
        margin-right: 1rem;
      }
    }
    
    .skills-tooltip {
      max-width: 300px !important;
      background-color: var(--white-color) !important;
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
      border-radius: 5px !important;
      padding: 1rem !important;
      color: var(--gray-color) !important;
      text-align: center !important;
      line-height: 1.5 !important;
      opacity: 1 !important;
    
      @media screen and (min-width: 2000px) {
        font-size: 1.75rem !important;
        max-width: 500px !important;
        line-height: 2 !important;
      }
    }

    .hashtag-list {
      max-width: 500px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 2px;
      margin-top: 1rem;
    }
  
    .hashtag {
        padding: 0.25rem 0.75rem;
        border-radius: 1rem;
        border-color: #fff;
        background-color: var(--white-color);
        font-weight: 800;
        font-size: 0.5rem;
        box-shadow: 0px 0px 10px #fff;
        cursor: pointer;
        transition: all 0.3s ease;
    
        &:hover {
          background-color: var(--secondary-color);
          color: #fff;
        }
        @media screen and (min-width: 2000px) {
          font-size: 1rem;
        }
      
        @media screen and (min-width: 798px) {
          font-size: 1rem;
        }
      }
    
      .role-text {
        font-size: 1rem;
        font-weight: 800;
        color: var(--black-color);
        text-align: left;
      
        @media screen and (min-width: 2000px) {
          font-size: 2rem;
        }
      
        @media screen and (min-width: 798px) {
          font-size: 1.25rem;
        }
      }
    
      .year-text {
        font-size: 1rem;
        font-weight: 800;
        color: var(--black-color);
        text-align: left;
      
        @media screen and (min-width: 2000px) {
          font-size: 2rem;
        }
      
        @media screen and (min-width: 798px) {
          font-size: 1.25rem;
        }
      }

      .company-text {
        font-size: 1rem;
        font-weight: 800;
        color: var(--black-color);
        text-align: left;
      
        @media screen and (min-width: 2000px) {
          font-size: 2rem;
        }
      
        @media screen and (min-width: 798px) {
          font-size: 1.25rem;
        }
      }
    
      .work-desc {
        font-size: 0.75rem;
        font-weight: 800;
        color: var(--black-color);
        text-align: left;
      
        @media screen and (min-width: 2000px) {
          font-size: 2rem;
        }
      
        @media screen and (min-width: 798px) {
          font-size: 1rem;
        }
      }