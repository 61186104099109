.app__skills-container {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1281px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.app__skills-exp {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  
    @media screen and (max-width: 1024px) {
      margin-top: 2rem;
      justify-content: center;
      align-items: center;
    }
  }
  
  .app__skills-exp-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1rem 0;
  }
  
  .app__skills-exp-works {
    flex: 1;
  
    .app__skills-exp-work {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 1rem;
      cursor: pointer;
  
      h4 {
        font-weight: 500;
      }
  
      p {
        font-weight: 400;
        color: var(--gray-color);
        margin-top: 5px;
      }
    }
  }
  
  .app__skills-exp-year {
    margin-right: 3rem;
  
    p {
      font-weight: 800;
      color: var(--secondary-color);
    }
  
    @media screen and (max-width: 450px) {
      margin-right: 1rem;
    }
  }
  
  .skills-tooltip {
    max-width: 300px !important;
    background-color: var(--white-color) !important;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
    padding: 1rem !important;
    color: var(--gray-color) !important;
    text-align: center !important;
    line-height: 1.5 !important;
    opacity: 1 !important;
  
    @media screen and (min-width: 2000px) {
      font-size: 1.75rem !important;
      max-width: 500px !important;
      line-height: 2 !important;
    }
  }

  .app__resume-exp {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .resume-card {
    max-width: 45em;
    min-height: 25em;
    background: var(--white-color);
    transition: 1s ease-in-out;
    clip-path: polygon(
      30px 0%,
      100% 0,
      100% calc(100% - 30px),
      calc(100% - 30px) 100%,
      0 100%,
      0% 30px
    );
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    @media screen and(max-width:798px) {
      margin-top: 0;
    }
    @media screen and(min-width:1281px) {
      position: sticky;
      top: 1rem; /* Adjust this value to position the card */
      z-index: 1; /* Ensure it appears on top */
    }
    @media screen and(max-width:1629px) {
      max-width:35em;
    }
    @media screen and(max-width:1291px) {
      max-width:30em;
    }
  }
  
  .resume-card span {
    font-weight: bold;
    color: #171717;
    text-align: center;
    display: block;
    font-size: 1em;
  }
  
  .resume-card .info {
    font-weight: 400;
    display: block;
    text-align: center;
    margin: 1rem;
    font-size: 0.75rem;
    color: var(--black-color);
    text-align: left;
  
    @media screen and (min-width: 2000px) {
      font-size: 2rem;
    }
  
    @media screen and (min-width: 798px) {
      font-size: 1em;
    }
  }
  
  .resume-card .img {
    width: 4.8em;
    height: 4.8em;
    background: #171717;
    border-radius: 15px;
    margin: auto;
  }
  
  .resume-card .share {
    margin-top: 1em;
    display: flex;
    justify-content: center;
    gap: 1em;
  }
  
  .resume-card a {
    color: var(--white-color);
    padding: 0.8em 1.7em;
    display: block;
    margin: auto;
    border-radius: 25px;
    border: none;
    font-weight: bold;
    background: var(--secondary-color);
    transition: 0.4s ease-in-out;
  }
  
  .resume-card a:hover {
    background: var(--white-color);
    color: var(--secondary-color);
    border: 1px var(--secondary-color);;
    cursor: pointer;
  }
  
  /* From Uiverse.io by AqFox */ 
    .spinner {
    width: 60px;
    height: 60px;
    animation: spinner-y0fdc1 2s infinite ease;
    transform-style: preserve-3d;
    margin:auto;
   }
   
   .spinner > div {
    background-color: rgba(0,77,255,0.2);
    height: 100%;
    position: absolute;
    width: 100%;
    border: 2px solid #004dff;
   }
   
   .spinner div:nth-of-type(1) {
    transform: translateZ(-22px) rotateY(180deg);
   }
   
   .spinner div:nth-of-type(2) {
    transform: rotateY(-270deg) translateX(50%);
    transform-origin: top right;
   }
   
   .spinner div:nth-of-type(3) {
    transform: rotateY(270deg) translateX(-50%);
    transform-origin: center left;
   }
   
   .spinner div:nth-of-type(4) {
    transform: rotateX(90deg) translateY(-50%);
    transform-origin: top center;
   }
   
   .spinner div:nth-of-type(5) {
    transform: rotateX(-90deg) translateY(50%);
    transform-origin: bottom center;
   }
   
   .spinner div:nth-of-type(6) {
    transform: translateZ(22px);
   }
   
   @keyframes spinner-y0fdc1 {
    0% {
     transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
    }
   
    50% {
     transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
    }
   
    100% {
     transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
    }
   }

   .role-text {
    font-size: 1rem;
    font-weight: 800;
    color: var(--black-color);
    text-align: left;
  
    @media screen and (min-width: 2000px) {
      font-size: 2rem;
    }
  
    @media screen and (min-width: 798px) {
      font-size: 1.25rem;
    }
  }

  .year-text {
    font-size: 1rem;
    font-weight: 800;
    color: var(--black-color);
    text-align: left;
  
    @media screen and (min-width: 2000px) {
      font-size: 2rem;
    }
  
    @media screen and (min-width: 798px) {
      font-size: 1.25rem;
    }
  }

  .company-text {
    font-size: 1rem;
    font-weight: 800;
    color: var(--black-color);
    text-align: left;
  
    @media screen and (min-width: 2000px) {
      font-size: 2rem;
    }
  
    @media screen and (min-width: 798px) {
      font-size: 1.25rem;
    }
  }

  .work-desc {
    font-size: 0.75rem;
    font-weight: 800;
    color: var(--black-color);
    text-align: left;
  
    @media screen and (min-width: 2000px) {
      font-size: 2rem;
    }
  
    @media screen and (min-width: 798px) {
      font-size: 0.8rem;
    }
  }

  .hashtag-list {
    max-width: 600px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2px;
    
  }

  .hashtag {
      padding: 0.25rem 0.75rem;
      border-radius: 1rem;
      border-color: #fff;
      background-color: var(--white-color);
      font-weight: 800;
      font-size: 0.5rem;
      box-shadow: 0px 0px 10px #fff;
      cursor: pointer;
      transition: all 0.3s ease;
  
      &:hover {
        background-color: var(--secondary-color);
        color: #fff;
      }
      @media screen and (min-width: 2000px) {
        font-size: 1rem;
      }
    
      @media screen and (min-width: 798px) {
        font-size: 0.75rem;
      }
    }