.app__skills {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__skills-container {
  width: 100%;

  display: flex;
  flex-direction: row;

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
}

.app__skills-list {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-right: 6rem;
  margin-left: 6rem;


  @media screen and (max-width: 900px) {
    margin-right: 0rem;
    margin-left: 0rem;
    justify-content: center;
    align-items: center;
  }
}

.app__skills-filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .app__skills-filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: var(--white-color);
    color: #000;
    font-weight: 800;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;

    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }

    @media screen and (min-width: 2000px) {
      padding: 1rem 2rem;
      border-radius: 0.85rem;
    }
  }

  .item-active {
    background-color: var(--secondary-color);
    color: #fff;
  }
}

.app__skills-item {
  flex-direction: column;
  text-align: center;

  margin: 1rem;

  transition: all 0.3s ease-in-out;

  div {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #fef4f5;

    img {
      width: 50%;
      height: 50%;
    }

    &:hover {
      box-shadow: 0 0 25px #fef4f5;
    }

    @media screen and (min-width: 2000px) {
      width: 150px;
      height: 150px;
    }

    @media screen and (max-width: 450px) {
      width: 70px;
      height: 70px;
    }
  }

  p {
    font-weight: 500;
    margin-top: 0.5rem;
  }

  @media screen and (min-width: 2000px) {
    margin: 1rem 2rem;

    p {
      margin-top: 1rem;
    }
  }
}